<template>
  <!--ul>
    <li
      v-for="prop in props"
      :key="prop"
    >
      <b>{{ prop }}:</b> {{ selectedDemo[prop] }}
    </li>
  </!--ul-->
  <div class="flex justify-center flex-wrap">
    <span
      v-if="selectedDemo != null" 
      style="width:90%;"
      class="
          fixed
          bottom-14
          sm:bottom-14
          sm:right-10
          lg:right-20  
          py-3
          px-2
          bg-red-900
          text-white
          xs:text-xs
          lg:text-lg
          xl:text-xl
          font-medium
          leading-snug
          rounded
          shadow-md
          active:bg-red-800 active:shadow-lg"
      :style="!$isMobile() ? 'z-index: 90' : ''"
    >
      {{ getDateText() }}: 
      {{ selectedDemo['street'] + ', ' + selectedDemo['zip'] + ' ' + selectedDemo['city'] }} - 
      {{ selectedDemo['topic'] ? selectedDemo['topic'] : '' }}
      <!-- {{ selectedDemo['route_desc'] ? selectedDemo['route_desc'] : '' }} -->
    </span>
  </div>
</template>

<script>
  export default {
    name: 'Inspect',
    components: {},
    props: {
      selectedDemo: {
          type: Object,
          default() {
              return {}
          }
      },
    },
    computed: {
      props() {
        return this.selectedDemo
          ? Object.keys(this.selectedDemo)
          : []
      },
      getFontSize() {
        return this.$fontSize.value;
      }      
    },
    methods: {
      isEnglish() {
        return this.$currentLanguage.value == 'en';
      },
      getDateText() {
        let startDate  = this.selectedDemo['start_date'];
        let startTime = this.selectedDemo['start_time'];
        let endTime = this.selectedDemo['end_time'];

        if (startTime == '00:00' && endTime == '00:00') {
          startTime = this.isEnglish() ? 'all day' : 'ganztägig';
          endTime = '';
        } else {
          startTime = startTime.replace(":00", "");
          endTime = endTime.replace(":00", "");
          if (this.isEnglish()) {            
            startTime = this.selectedDemo['start_time_english'];
            endTime = this.selectedDemo['end_time_english'];
          } else {
            endTime = endTime ? endTime + ' Uhr' : '';
          }
        }

        let text = startDate + ", "
          + startTime
          + (endTime ? "-" : "")
          + endTime;
        return text;
      },
      getEnglishDay(day) {
        switch (day) { // hour
          case 'Mo.':
            return 'Mon.'
          case 'Di.':
            return 'Tue.'
          case 'Mi.':
            return 'Wed.'
          case 'Do.':
            return 'Thu.'
          case 'Fr.':
            return 'Fri'
          case 'Sa.':
            return 'Sat.'
          case 'So.':
            return 'Sun.'
          default:
            return day;
        }   
      },
    }
  }
</script>
